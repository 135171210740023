import React from "react";
import Navbar from "../Navbar";
import SideBar from "./SideBar";

const FooterEdit = () => {
  return (
    <>
      <Navbar />
      <SideBar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit Footer Information</h5>
            <div className="col-lg-5 nav-column">
              <div>
                <form>
                  <div className="mb-3">
                    <label for="nav-items1" className="form-label">
                      Privacy Policy
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Privacy Policy"
                    />
                  </div>
                  <div className="mb-3">
                  <label for="nav-items2" className="form-label">
                      Terms & Conditions
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Terms & Conditions"
                    />
                  </div>
                  <div className="mb-3">
                  <label for="nav-items3" className="form-label">
                      Copyright
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="All copyright reserved @2023"
                    />
                  </div>
                  <button type="submit" className="btn btn-success">
                  Update
                </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterEdit;
