import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Contact = () => {
  return (
    <>
      <Navbar />

      <div className="container-fluid contact">
        <div className="contact-banner">
          <h1>Contact Us</h1>
          <p>
            Feel free to contact us any time. We will get back to you as soon as
            we can.
          </p>
        </div>
      </div>

      <div className="container">
        <div className="col-lg-10 mx-auto c-form">
          <h1>Send us a message</h1>
          <form action="/">
            <div className="row">
              <div className="col-lg-6">
                <div className="c-field">
                  <label htmlFor="name">
                    Name <span>*</span>
                  </label>
                  <input type="text" placeholder="Your name" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="c-field">
                  <label htmlFor="email">
                    Email <span>*</span>
                  </label>
                  <input type="text" placeholder="Email Address" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="c-field">
                  <label htmlFor="phone">Phone Number</label>
                  <input type="text" placeholder="+91" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="c-field">
                  <label htmlFor="city">City </label>
                  <input type="text" placeholder="City" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="c-field">
                  <label htmlFor="description">Description</label>
                  <textarea
                    name="description"
                    placeholder="Example..."
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="contact-button">
              <button>Send Message</button>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
