import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Details = () => {
  return (
    <>
      {/* NAVBAR */}

      <Navbar />

      {/* MAIN CONTENT */}

      <div className="container details-container">
        <h1>
          <span>SAFe® 3 Scrum Master</span> Course Overview
        </h1>
        <p>
          The SAFe Scrum Master Certification course acquaints you with the
          principles of a scaled agile framework enterprise and guides you
          through the process of planning and executing the Program Increment.
          Plan and execute Program Increment, master agile development at scale,
          iteration planning, and building high-performing teams.
          Course Overview The SAFe Scrum Master Certification course acquaints
          you with the principles of a scaled agile framework enterprise and
          guides you through the process of planning and executing the Program
          Increment. Plan and execute Program Increment, master agile
          development at scale, iteration planning, and building high-performing
          teams. Course Overview The SAFe Scrum Master Certification course
          acquaints you with the principles of a scaled agile framework
          enterprise and guides you through the process of planning and
          executing the Program Increment. Plan and execute Program Increment,
          master agile development at scale, iteration planning, and building
          high-performing teams.
        </p>
        <p>
          If you want to become a certified scrum master with the skills to help
          leading companies succeed with Agile and scrum, the SAFe® Scrum
          Master course is for you. Learn how scrum masters empower and lead
          Agile teams. Understand how to facilitate Agile events like iteration
          planning, team sync, and iteration retrospectives. And discover how to
          help teams plan and complete their work using SAFe Scrum and SAFe
          Kanban. You’ll also learn the principles of servant leadership, so you
          can help develop high-performing, engaged, continuously improving
          teams in your own organization.
        </p>

        <div>
          <div className="col-lg-10 mx-auto details-img">
            <img src="imgs/Agp-Blog-Jan-3 1.png" alt="" />
          </div>
        </div>

        <h3>What’s included:</h3>
        <div>
          <li>
            Course workbook and SAFe Studio access to help prepare you to take
            the certification exam, claim their digital badge, and tools to get
            started in your SAFe role
          </li>
          <li>Remote learning via SAFe® Virtual Classrooms</li>
          <li>
            One-year access to SAFe® Studio with your first class attendance
          </li>
          <li>Team Event Facilitator Guides</li>
          <li>Team Event Facilitator Guides</li>
          <li>Online collaboration templates for all SAFe team events</li>
          <li>Toolkits to help prepare and execute PI Planning</li>
        </div>

        <h3>After taking the course, you’ll know how to:</h3>
        <div>
          <li>Empower and lead an Agile team</li>
          <li>
            Facilitate Agile events like iteration planning, team sync, and
            iteration retrospectives
          </li>
          <li>Help teams plan and complete their work</li>
          <li>
            You’ll also learn the principles of servant leadership, which are
            crucial for key SAFe team roles. These skills will enable you to
            develop high-performing, engaged, and continuously improving teams
            in your own organization.
          </li>
        </div>

        <div className="detail-button d-flex justify-content-center mt-5">
          <Link to="/price"><button>Proceed to Buy</button></Link>
        </div>
      </div>

      {/* FOOTER */}

      <Footer />
    </>
  );
};

export default Details;
