import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
    
    {/* THIS IS SIGN UP PAGE FOr NEW USER */}

    <Navbar />

    <div className="container-fluid login-main">
        {/* <div className="row"> */}
          <div className="col-lg-6 login-c">
            <div className='col-lg-8 mx-auto login'>
              <h1>Welcome Back</h1>
              <h3>Signup to continue learning</h3>
              <form action="/">
              <div className='login-form'>
                <label htmlFor="name">
                  Name
                </label>
                <input type="text" placeholder='Enter your name' />
                <label htmlFor="email">
                  Email
                </label>
                <input type="text" placeholder='Enter your email' />
                <label htmlFor="password">
                  Password
                </label>
                <div class="input-group l-group">
                <input
                  type={showPassword ? "text" : "password"}
                  class="form-control l-control"
                  placeholder="Password"
                  aria-label="password"
                  aria-describedby="basic-addon1"
                />
                <div class="input-group-prepend l-prepend">
                  <span
                    class="input-group-text l-group-text"
                    id="basic-addon1"
                    onClick={togglePasswordVisibility}
                  >
                    <i className="bi bi-eye"></i>
                  </span>
                </div>
              </div>
              </div>
              <div className='login-agree1'>
                <input type="checkbox" />
                <span className='agree'> I agree to the terms and conditions</span>
              </div>
              <div className='login-button'>
                <Link to="/profile"><button>Sign up</button></Link>
              </div>
              <div className='acc'>
                <p>Already have an account ? <span><Link to="/signin">Sign in</Link></span></p>
              </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6 login-image">
            <img src="imgs/girl.png" alt="" />
          </div>
        {/* </div> */}
    </div>

    <Footer classes={true} />
    
    </>
  )
}

export default Login