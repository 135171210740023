import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Profile = () => {
  return (
    <>
      <Navbar login={true} />

      <div className="container">
        <div className="col-lg-8 mx-auto p-4 profile-form">
          <h1>Profile Settings</h1>
          <p>These are your personal details, You can edit these here</p>
          <form action="/">
          <div className="p-block row">
            <div className="col-lg-6">
              <div className="p-field">
                <label htmlFor="fname">First Name</label>
                <div class="input-group p-group">
                  <div class="input-group-prepend p-prepend">
                    <span class="input-group-text p-group-text" id="basic-addon1">
                      <i className="bi bi-person"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control p-control"
                    placeholder="First Name"
                    aria-label="fname"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="p-field">
                <label htmlFor="lname">Last Name</label>
                <div class="input-group p-group">
                  <div class="input-group-prepend p-prepend">
                    <span class="input-group-text p-group-text" id="basic-addon1">
                      <i className="bi bi-person"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control p-control"
                    placeholder="Last Name"
                    aria-label="lname"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-block row">
            <div className="col-lg-6">
              <div className="p-field">
                <label htmlFor="phone">Phone Number</label>
                <div class="input-group p-group">
                  <div class="input-group-prepend p-prepend">
                    <span class="input-group-text p-group-text" id="basic-addon1">
                      <i className="bi bi-telephone"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control p-control"
                    placeholder="00000 00000"
                    aria-label="phone"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="p-field">
                <label htmlFor="email">Email ID</label>
                <div class="input-group p-group">
                  <div class="input-group-prepend p-prepend">
                    <span class="input-group-text p-group-text" id="basic-addon1">
                      <i className="bi bi-envelope"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control p-control"
                    placeholder="abc@xyz.com"
                    aria-label="email"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-block row">
            <div className="col-lg-6">
              <div className="p-field">
                <label htmlFor="address">Address</label>
                <div class="input-group p-group">
                  <div class="input-group-prepend p-prepend">
                    <span class="input-group-text p-group-text" id="basic-addon1">
                      <i className="bi bi-house-door"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control p-control"
                    placeholder="Your Address"
                    aria-label="address"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="p-field">
                <label htmlFor="dob">Date of Birth</label>
                <div class="input-group p-group">
                  
                  <input
                    type="text"
                    class="form-control p-control"
                    placeholder="dd/mm/yyyy"
                    aria-label="dob"
                    aria-describedby="basic-addon1"
                  />
                  <div class="input-group-prepend p-prepend">
                    <span class="input-group-text p-group-text" id="basic-addon1">
                      <i className="bi bi-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-block row">
            <div className="col-lg-6">
              <div className="p-field">
                <label htmlFor="gender">Gender</label>
                <div class="input-group p-group">
                  <select name="gender" id="gender">
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                    <option value="none">Prefer not to say</option>
                  </select>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="p-buttons">
            <button className="b1">Cancel</button>
            <button className="b2">Save Changes</button>
          </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Profile;
