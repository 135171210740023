import React from 'react';
import Navbar from '../Navbar';
import SideBar from './SideBar';

const Main = () => {
  return (
    <>

    <Navbar />

    <SideBar />
    
    <div className="home">
    <div class="toggle-sidebar" style={{display: "none"}}>
            <i class='bi bi-menu'></i>
        </div>
        <div className='admin-banner'>

            <div className="row">
            <div className="col-lg-4">
                    <div className="admin-dashbox">
                        <h2>10</h2>
                        <h3 className="pt-2">Total Registered Users</h3>
                    </div>
                </div>
            <div className="col-lg-4">
                    <div className="admin-dashbox">
                        <h2>10</h2>
                        <h3 className="pt-2">Total Bookings</h3>
                    </div>
                </div>
            <div className="col-lg-4">
                    <div className="admin-dashbox">
                        <h2>10</h2>
                        <h3 className="pt-2">Total Blogs</h3>
                    </div>
                </div>
            </div>

        </div>
    </div>



    </>
  )
}

export default Main