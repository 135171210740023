import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ classes }) => {
  return (
    <div
      className="container-fluid footer-c py-4"
      style={classes ? {marginTop: "0%"} : {marginTop: "5%"}}
    >
      <div className="footer-list container">
        <div>
          <Link className="footer-link me-4" to="/terms">
            Terms & Conditions
          </Link>
          <Link className="footer-link" to="/privacy">
            Privacy Policy
          </Link>
        </div>
        <h1 className="footer my-auto">All copyright reserved @2023</h1>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  classes: false,
};


export default Footer;
