import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Price = () => {
  return (
    <>
      <Navbar />

      <div className="container price">
        <div className="p-header">
          <h1>
            <span>SAFe® 3 Scrum Master</span> Course Overview
          </h1>
          <p>
            The SAFe Scrum Master Certification course acquaints you with the
            principles of a scaled agile framework enterprise and guides you
            through the process of planning and executing the Program Increment.
            Plan and execute Program Increment, master agile development at
            scale, iteration planning, and building high-performing teams.
            Course Overview The SAFe Scrum Master Certification course acquaints
            you with the principles of a scaled agile framework enterprise and
            guides you through the process of planning and executing the Program
            Increment. Plan and execute Program Increment, master agile
            development at scale, iteration planning, and building
            high-performing teams. Course Overview The SAFe Scrum Master
            Certification course acquaints you with the principles of a scaled
            agile framework enterprise and guides you through the process of
            planning and executing the Program Increment. Plan and execute
            Program Increment, master agile development at scale, iteration
            planning, and building high-performing teams.
          </p>
        </div>
        <div className="p-cards row">
            <div className="col-lg-6">
                <div className="p-card1">
                    <div>
                        <h2>SAFe® 3 Scrum Master</h2>
                        <h4>09:00 - 17:30 IST</h4>
                        <h6>Instructor: <span>Meiyappan Muthu</span></h6>
                    </div>
                    <h3> ₹ 40,499.00</h3>
                </div>
                <div className="p-card2">
                    <label htmlFor="slots">Available Slots</label>
                    <select name="slots" id="slots">
                        <option value="1">09:00 - 17:30</option>
                        <option value="1">08:00 - 16:30</option>
                        <option value="1">07:00 - 15:30</option>
                        <option value="1">06:00 - 14:30</option>
                    </select>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="p-card3">
                    <h3>Gift Card / Discount code</h3>
                    <div className="row p-card-input">
                        <div className="col-8">
                          <input type="text" />
                        </div>
                        <div className="col-4 p-card-button">
                          <button>Apply</button>
                        </div>
                    </div>
                    <div className="p-line">
                      <span>Subtotal</span>
                      <span>$160.00</span>
                    </div>
                    <div className="p-line">
                      <span>GST (6.5%)</span>
                      <span>$4.23</span>
                    </div>
                    <div className="p-line1">
                      <span>Total due</span>
                      <span className="p-span">$164.23</span>
                    </div>
                    <div className="p-proceed">
                      <button>Proceed</button>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Price;
