import './App.css';
import Blogdetails from './components/Blogdetails';
import Blogs from './components/Blogs';
import Contact from './components/Contact';
import Courses from './components/Courses';
import Details from './components/Details';
import Forgot from './components/Forgot';
import Landing from './components/Landing';
import Login from './components/Login';
import Price from './components/Price';
import Privacy from './components/Privacy';
import Profile from './components/Profile';
import Reset from './components/Reset';
import Signin from './components/Signin';
import Terms from './components/Terms';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from './components/admin/Main';
import AdminTable from './components/admin/AdminTable';
import LogoEdit from './components/admin/LogoEdit';
import NavbarEdit from './components/admin/NavbarEdit';
import FooterEdit from './components/admin/FooterEdit';
import HomeEdit from './components/admin/HomeEdit';
import DetailsEdit from './components/admin/DetailsEdit';
import BlogsEdit from './components/admin/BlogsEdit';
import CourseEdit from './components/admin/CourseEdit';
import FooterContentEdit from './components/admin/FooterContentEdit';
import FaqEdit from './components/admin/FaqEdit';
import ProfileEdit from './components/admin/ProfileEdit';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/details" element={<Details />} />
          <Route exact path="/courses" element={<Courses />} /> 
         <Route exact path="/blogDetails" element={<Blogdetails />} />
         <Route exact path="/blogs" element={<Blogs />} />
         <Route exact path="/contact" element={<Contact />} />
         <Route exact path="/signup" element={<Login />} />
         <Route exact path="/signin" element={<Signin />} />
         <Route exact path="/profile" element={<Profile />} />
         <Route exact path="/price" element={<Price />} />
         <Route exact path="/forgot" element={<Forgot />} />
         <Route exact path="/reset" element={<Reset />} />

          {/* ADMIN */}

         <Route exact path="/main" element={<Main />} />
         <Route exact path="/table" element={<AdminTable />} /> 
         <Route exact path="/logo" element={<LogoEdit />} /> 
         <Route exact path="/navedit" element={<NavbarEdit />} /> 
         <Route exact path="/footeredit" element={<FooterEdit />} /> 
         <Route exact path="/homeedit" element={<HomeEdit />} /> 
         <Route exact path="/detailsedit" element={<DetailsEdit />} /> 
         <Route exact path="/blogsedit" element={<BlogsEdit />} /> 
         <Route exact path="/courseedit" element={<CourseEdit />} /> 
         <Route exact path="/footerCedit" element={<FooterContentEdit />} /> 
         <Route exact path="/faqedit" element={<FaqEdit />} /> 
         <Route exact path="/profileedit" element={<ProfileEdit />} /> 

        </Routes>
      </Router>
    </div>
  );
}

export default App;
