import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link, useLocation } from "react-router-dom";

const Navbar = ({ login }) => {

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light py-3 bg-white sticky-top">
      <div className="container nav-fluid">
        <a className="navbar-brand" href="index.html">
          <h1>Scrum Training</h1>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link className={`nav-link ${isActive('/')}`} to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${isActive('/courses')}`} to="/courses">
                {login ? "My Courses" : "Courses"}
              </Link>
            </li>
            <li className="nav-item">
            <Link className={`nav-link ${isActive('/blogs')}`} to="/blogs">
                  Blogs
                </Link>
            </li>
            <li className="nav-item">
            <Link className={`nav-link ${isActive('/contact')}`} to="/contact">
                  Contact Us
                </Link>
            </li>
            {/* <li className="nav-item">
            {login ? (
                <Link className={`nav-link ${isActive('/contact')}`} to="/contact">
                  Contact Us
                </Link>
              ) : (
                <Link className={`nav-link ${isActive('/details')}`} to="/details">
                  About Us
                </Link>
              )}
            </li> */}
          </ul>
          <div className="d-flex nav-buttons">
            {login ? (
              <Link to="/profile"  className="text-decoration-none">
                <div>
                <img src="imgs/xyz.png" alt="" />{" "}
                <i className="bi bi-chevron-down text-black"></i>
              </div>
              </Link>
            ) : (
              <Link to="/signin">
                <button className="first-btn me-3" type="submit">
                Login →
              </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

Navbar.defaultProps = {
  login: false,
};

export default Navbar;
