import React, { useState } from "react";
import Navbar from "../Navbar";
import SideBar from "./SideBar";

const HomeEdit = () => {
  const [imageUrl, setImageUrl] = useState(null);
  const [activeTab, setActiveTab] = useState("banner");

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <Navbar />
      <SideBar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row home-toggletab">
            <h4>Edit Homepage Informtion</h4>
            <div className="col-lg-11 hometab-column">
              
              <div className="tab">
                <button
                  className={`tablinks  ${activeTab === "banner" && "active"}`}
                  onClick={() => openTab("banner")}
                  id="banner-button"
                >
                  Main Banner
                </button>
                <button
                  className={`tablinks ${activeTab === "courses" && "active"}`}
                  onClick={() => openTab("courses")}
                  id="courses-button"
                >
                  Our Courses Section
                </button>
                <button
                  className={`tablinks ${activeTab === "about" && "active"}`}
                  onClick={() => openTab("about")}
                  id="about-button"
                >
                  About Instructor
                </button>
                <button
                  className={`tablinks ${activeTab === "training" && "active"}`}
                  onClick={() => openTab("training")}
                  id="training-button"
                >
                  Training Schedule
                </button>
              </div>

              <div
                id="banner"
                className={`tabcontent ${
                  activeTab === "banner" ? "active" : ""
                }`}
              >
                <div className="season_content">
                  <form>
                    <div className="mb-4">
                      <h5>Title</h5>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="SAFe® 6 Scrum Master (SSM) Certification Training"
                      />
                    </div>
                    <div className="mb-4">
                      <h5>Subtitle</h5>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Subtitle heading"
                      />
                    </div>
                    <div className="mb-4">
                      <h5>Content</h5>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Content"
                      />
                    </div>
                  </form>
                  <form>
                    <h5>Status</h5>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Show</option>
                      <option value="1">Hide</option>
                    </select>
                  </form>
                  <button type="submit" className="btn btn-success">
                    Update
                  </button>
                </div>
              </div>

              <div
                id="courses"
                className={`tabcontent ${
                  activeTab === "courses" ? "active" : ""
                }`}
              >
                <div className="season_content">
                  <form>
                    <div className="mb-4">
                      <h5>Title</h5>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="SAFe® 6 Scrum Master (SSM) Course overview"
                      />
                    </div>
                    <div className="mb-4">
                      <h5>Content</h5>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Content"
                      />
                    </div>
                    <div className="mb-4">
                      <h5>Button</h5>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Get Started"
                      />
                    </div>
                  </form>
                  <form>
                    <h5>Status</h5>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Show</option>
                      <option value="1">Hide</option>
                    </select>
                  </form>
                  <button type="submit" className="btn btn-success">
                    Update
                  </button>
                </div>
              </div>

              <div
                id="about"
                className={`tabcontent ${
                  activeTab === "about" ? "active" : ""
                }`}
              >
                <div className="season_content">
                  <form>
                    <div className="mb-4">
                      <h5>Title</h5>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        aria-describedby="title"
                        placeholder="About Meiyappan Muthu"
                      />
                    </div>
                    <div className="mb-4">
                      <h5>Content</h5>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        aria-describedby="title"
                        placeholder="Content"
                      />
                    </div>
                    <div className="mb-4">
                      <h5>Image</h5>
                      <input
                        type="file"
                        onchange={handleFileChange}
                        className="pt-3"
                      />
                      {imageUrl && (
                        <img
                          src={imageUrl}
                          alt="Preview"
                          style={{ width: "100px", height: "100px" }}
                        />
                      )}
                    </div>
                  </form>
                  <form>
                    <h5>Status</h5>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Show</option>
                      <option value="1">Hide</option>
                    </select>
                  </form>
                  <button type="submit" className="btn btn-success">
                    Update
                  </button>
                </div>
              </div>
              <div
                id="training"
                className={`tabcontent ${
                  activeTab === "training" ? "active" : ""
                }`}
              >
                <div className="season_content">
                  <form>
                    <div className="mb-4">
                      <h5>Title</h5>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        aria-describedby="title"
                        placeholder="Training Schedule"
                      />
                    </div>
                    <div className="mb-4">
                      <h5>Subtitle</h5>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        aria-describedby="title"
                        placeholder="subtitle"
                      />
                    </div>
                    <div className="mb-4">
                      <h5>Schedule Course</h5>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        aria-describedby="title"
                        placeholder="schedule"
                      />
                    </div>
                  </form>
                  <form>
                    <h5>Status</h5>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Show</option>
                      <option value="1">Hide</option>
                    </select>
                  </form>
                  <button type="submit" className="btn btn-success">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeEdit;
