import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from 'react-router-dom';

const Landing = () => {

    const [ showItem, setShowItem ] = useState(null);

    const handleClick = (index) => {
      setShowItem(showItem === index ? null : index);
    };


  return (
    <>
      <Navbar />

      {/* BANNER */}

      <div className="container l-banner">
        <div className="row l-row">
          <div className="col-lg-6">
            <div className="lb-content">
              <h1>SAFe® 6 Scrum Master (SSM) Certification Training</h1>
              <h3>Delivered by AgileMania, Scaled Agile Partner - GOLD SPCT</h3>
              <p>
                The SAFe® <u>Scrum Master</u> course equips attendees to coach
                Agile teams to deliver business value at scale. The course
                teaches you the tactical skills needed to be an effective Scrum
                Master in a SAFe organization. It also gives you the guidance
                and tools necessary for working in remote environments with
                distributed teams.
              </p>
              <div className="lb-button">
                <Link to="/price" className="text-decoration-none"><button>View Training Schedule</button></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="lb-img">
              <img src="imgs/9796308 1.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* COURSES */}

      <div className="container-fluid course py-5 mt-5">
        <div className="container">
          <div className="course-heading pb-4 col-lg-6 mx-auto">
            <h1>
              Our <span>Courses</span>
            </h1>
            <p>
              Empower Your Leadership Journey: Unleash the Full Potential of
              Scrum Mastery with Our Comprehensive Course
            </p>
          </div>
          <div className="course-row row">
            <div className="col-lg-6">
              <div className="course-card">
                <h1>
                  SAFe® 6 Scrum Master <span> Course Overview</span>
                </h1>
                <p>
                  The SAFe Scrum Master Certification course acquaints you with
                  the principles of a scaled agile framework enterprise and
                  guides you through the process of planning and executing the
                  Program Increment. Plan and execute Program Increment, master
                  agile development at scale, iteration planning, and building
                  high-performing teams.
                </p>
                <div className="my-4">
                  <li>
                    <i className="bi bi-check2-all"></i> 16 hours of live
                    instructor-led sessions
                  </li>
                  <li>
                    <i className="bi bi-check2-all"></i> One-year membership to
                    the SAFe Community Platform
                  </li>
                  <li>
                    <i className="bi bi-check2-all"></i> SAFe® 6.0 Scrum Master
                    (SSM) exam cost included in course fee
                  </li>
                  <li>
                    <i className="bi bi-check2-all"></i> Get exam success with
                    comprehensive exam support
                  </li>
                </div>
                <div className="course-button">
                  <Link className="text-decoration-none" to="/details"><button>Learn More</button></Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="course-card">
                <h1>
                  SAFe® 6 Scrum Master <span> Course Overview</span>
                </h1>
                <p>
                  The SAFe® <u> Scrum Master </u> course equips attendees to
                  coach Agile teams to deliver business value at scale. The
                  course teaches you the tactical skills needed to be an
                  effective Scrum Master in a SAFe organization. It also gives
                  you the guidance and tools necessary for working in remote
                  environments with distributed teams.
                </p>
                <div className="my-4">
                  <li>
                    <i className="bi bi-check2-all"></i> Get trained by SAFe®
                    Practice Consultants (SPCs)
                  </li>
                  <li>
                    <i className="bi bi-check2-all"></i> Earn 16 SEUs for
                    continual learning
                  </li>
                  <li>
                    <i className="bi bi-check2-all"></i> Support effective
                    Program
                  </li>
                  <li>
                    <i className="bi bi-check2-all"></i> Scrum in a SAFe
                    enterprise
                  </li>
                </div>
                <div className="course-button">
                <Link className="text-decoration-none" to="/details"><button>Learn More</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ABOUT */}

      <div className="container about py-5">
        <div className="row">
          <div className="col-lg-6">
            <div className="about-left">
              <h1 className="py-4">
                About <span>Meiyappan Muthu</span>
              </h1>
              <p>
                I’m an Experienced Lead Business Analyst and Certified
                ScrumMaster® with a proven track record in implementing SAFe®
                practices. Bringing over 10 years of industry expertise, I
                specialize in Data Operations, Digital Transformation, Software
                Development, App Migration, and System & Cloud Administration.
                As an Agile Enthusiast and AWS AZURE Architect Certified
                professional, I serve as a change agent, collaborating
                seamlessly with key stakeholders in business and technology.
              </p>
              <p>
                My skills extend to translating user stories into actionable
                solutions, developing system specifications, and crafting
                technical designs. With a solid foundation in Agile, Scrum, and
                ITIL, I am dedicated to delivering solutions that drive business
                growth, foster organizational development, and optimize systems.
                As a SAFe Scrum Master Validation (6.0) holder, I bring a
                demonstrated ability to identify and troubleshoot problems
                effectively."
              </p>
              <button>
                <i className="bi bi-linkedin"></i>
              </button>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-img">
              <img src="imgs/man.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* TRAINING SCHEDULE */}

      <div className="container t-container">
        <div className="t-heading col-lg-6 mx-auto">
          <h1>Training Schedule</h1>
          <p>
            Empower Your Leadership Journey: Unleash the Full Potential of Scrum
            Mastery with Our Comprehensive Course
          </p>
        </div>
        <div className="t-cards row">
          <div className="col-lg-6">
            <div className="t-card">
              <div className="t-left pt-2">
                <h2>SAFe® 6 Scrum Master</h2>
                <h4>08:00 - 16:30 IST</h4>
                <p>
                  Instructor:<span> Meiyappan Muthu</span>
                </p>
              </div>
              <div className="t-right">
              <Link className="text-decoration-none" to="/price"><button>Enroll Now</button></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="t-card">
              <div className="t-left pt-2">
                <h2>SAFe® 6 Scrum Master</h2>
                <h4>09:00 - 17:30 IST</h4>
                <p>
                  Instructor:<span> Meiyappan Muthu</span>
                </p>
              </div>
              <div className="t-right">
              <Link className="text-decoration-none" to="/price"><button>Enroll Now</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FREQUENTLY ASKED QUESTIONS */}

      <div className="container faq">
        <div className="f-heading col-lg-6 mx-auto">
          <h1>Frequently Asked Questions</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            varius enim in eros elementum tristique.
          </p>
        </div>
        <div className="row">
          <div className="mx-auto col-lg-10 faq-c row">
            <div className="col-lg-3">
              <div className="faq-img">
                <img src="imgs/FAQs-amico.png" alt="" />
              </div>
            </div>
            <div className="col-lg-9">
              <div className="faq-content">
                <div className="f-card">
                  <div className="d-flex justify-content-between">
                    <h3>How do i Enroll in this online training</h3>
                    <i class="bi bi-chevron-down" onClick={() => handleClick(1)}></i>
                  </div>
                  {showItem === 1 && <div>
                    <p>
                      You can enroll in this training on our website and make an
                      online payment using any of the following options You can
                      enroll in this You can enroll in this training on
                    </p>
                  </div>}
                </div>
                <div className="f-card">
                  <div className="d-flex justify-content-between">
                    <h3>How do i Enroll in this online training</h3>
                    <i class="bi bi-chevron-down" onClick={() => handleClick(2)}></i>
                  </div>
                  {showItem === 2 && <div>
                    <p>
                      You can enroll in this training on our website and make an
                      online payment using any of the following options You can
                      enroll in this You can enroll in this training on
                    </p>
                  </div>}
                </div>
                <div className="f-card">
                  <div className="d-flex justify-content-between">
                    <h3>How do i Enroll in this online training</h3>
                    <i class="bi bi-chevron-down" onClick={() => handleClick(3)}></i>
                  </div>
                  {showItem === 3 && <div>
                    <p>
                      You can enroll in this training on our website and make an
                      online payment using any of the following options You can
                      enroll in this You can enroll in this training on
                    </p>
                  </div>}
                </div>
                <div className="f-card">
                  <div className="d-flex justify-content-between">
                    <h3>How do i Enroll in this online training</h3>
                    <i class="bi bi-chevron-down" onClick={() => handleClick(4)}></i>
                  </div>
                  {showItem === 4 && <div>
                    <p>
                      You can enroll in this training on our website and make an
                      online payment using any of the following options You can
                      enroll in this You can enroll in this training on
                    </p>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Landing;
