import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Blogdetails = () => {
  return (
    <>
      <Navbar />

      <div className="container-fluid bd-banner">
        <div className="bd-box">
          <h1>Blog</h1>
          <p>The proper uѕе оf ѕаfеtу ѕіgnѕ іѕ a соmрulѕоrу requirement.</p>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Blog Search"
              aria-label="blog search"
              aria-describedby="basic-addon2"
            />
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">
                Search Now
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="col-lg-10 mx-auto bd-container">
          <h1 className="col-lg-10 my-4">
            When the musics over turn off the light says Jim Morrison
          </h1>
          <div className="profile">
            <img src="imgs/profile.png" alt="" />
            <span>Shuvo Roy | July 4, 2024 | 3 min read</span>
          </div>
          <div className="bd-img">
            <img src="imgs/Agp-Blog-Jan-3 1.png" alt="" />
          </div>
          <p>
            Lemagni dolores eos qui ratione voluptatem sequi nesciunt. Neque
            porro quisquam est, qui dolorem ipsum quia dolor sit ametempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo
          </p>
          <p>
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit
            esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum. Sed ut perspiciatis unde omnis iste natus error
            sit volup
          </p>

          <h3>Mauris et neque hendrerit bortis turpis giat nisl</h3>
          <p>
            Lemagni dolores eos qui ratione voluptatem sequi nesciunt. Neque
            porro quisquam est, qui dolorem ipsum quia dolor sit ametempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo
          </p>

          <h3>Mauris et neque hendrerit bortis turpis giat nisl</h3>
          <p>
            Lemagni dolores eos qui ratione voluptatem sequi nesciunt. Neque
            porro quisquam est, qui dolorem ipsum quia dolor sit ametempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo
          </p>
        </div>

        <div className="col-lg-8 mx-auto bd-form">
          <h1>Comments</h1>
          <form action="/">
            <div className="row my-3">
            <div className="col-lg-6">
              <div>
                <label htmlFor="name">Name <span>*</span></label>
                <input type="text" />
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <label htmlFor="name">Email <span>*</span></label>
                <input type="text" />
              </div>
            </div>
            </div>
            <div className="col-lg-12 my-3">
              <label htmlFor="website">
              Website <span>*</span>
              </label>
              <input type="text" />
            </div>
            <div className="col-lg-12 my-3">
              <label htmlFor="comment">
              Post a comment <span>*</span>
              </label>
              <textarea></textarea>
            </div>
            <div className="bd-button">
              <button>Submit Now</button>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Blogdetails;
