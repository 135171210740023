import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

const Forgot = () => {
  return (
    <>
    

    <Navbar />

      <div className="container-fluid login-main">
        {/* <div className="row"> */}
        <div className="col-lg-6 login-c">
          <div className="col-lg-8 mx-auto login">
            <h1>Forgot Password ?</h1>
            <h3>Don't worry we can help</h3>
            <form action="/">
            <div className="login-form">
              <label htmlFor="email">Email Address</label>
              <input type="text" placeholder="Enter your email" />
            </div>
            
            <div className="login-button">
              <Link to="/reset"><button>Continue</button></Link>
            </div>
            <div className="acc">
              <p>
                Remembered your password ?{" "}
                <span>
                  <Link to="/signin">Sign in</Link>
                </span>
              </p>
            </div>
            </form>
          </div>
        </div>
        <div className="col-lg-6 login-image">
          <img src="imgs/girl.png" alt="" />
        </div>
        {/* </div> */}
      </div>


      <Footer classes={true} />
    
    </>
  )
}

export default Forgot