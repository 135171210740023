import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

const Reset = () => {

    const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
    

    <Navbar />

      <div className="container-fluid login-main">
        {/* <div className="row"> */}
        <div className="col-lg-6 login-c">
          <div className="col-lg-8 mx-auto login">
            <h1>Reset Password</h1>
            <h3> </h3>
            <form action="/">
            <div className="login-form">
              <label htmlFor="newpass">New Password</label>
              <div class="input-group l-group">
                <input
                  type={showPassword ? "text" : "password"}
                  class="form-control l-control"
                  placeholder="Enter new Password"
                  aria-label="password"
                  aria-describedby="basic-addon1"
                />
                <div class="input-group-prepend l-prepend">
                  <span
                    class="input-group-text l-group-text"
                    id="basic-addon1"
                    onClick={togglePasswordVisibility}
                  >
                    <i className="bi bi-eye"></i>
                  </span>
                </div>
              </div>
              <label htmlFor="confirmpass">Confirm new Password</label>
              <div class="input-group l-group">
                <input
                  type={showPassword ? "text" : "password"}
                  class="form-control l-control"
                  placeholder="Confirm your Password"
                  aria-label="password"
                  aria-describedby="basic-addon1"
                />
                <div class="input-group-prepend l-prepend">
                  <span
                    class="input-group-text l-group-text"
                    id="basic-addon1"
                    onClick={togglePasswordVisibility}
                  >
                    <i className="bi bi-eye"></i>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="login-button">
              <Link to="/signin"><button>Continue</button></Link>
            </div>
            <div className="acc">
              <p>
                Remembered your password ?{" "}
                <span>
                  <Link to="/signin">Sign in</Link>
                </span>
              </p>
            </div>
            </form>
          </div>
        </div>
        <div className="col-lg-6 login-image">
          <img src="imgs/girl.png" alt="" />
        </div>
        {/* </div> */}
      </div>


      <Footer classes={true} />
    
    </>
  )
}

export default Reset