import React from "react";
import Navbar from "../Navbar";
import SideBar from "./SideBar";

const ProfileEdit = () => {
  return (
    <>
      <Navbar />
      <SideBar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div class="container pt-3">
          <div class="row top-barcolumn">
            <h5>Edit Profile Information</h5>
            <div class="col-lg-10 nav-column">
              <div>
                <form>
                  <div class="mb-3">
                    <div className="p-block row">
                      <div className="col-lg-6">
                        <div className="p-field">
                          <label htmlFor="fname">First Name</label>
                          <div class="input-group p-group">
                            <div class="input-group-prepend p-prepend">
                              <span
                                class="input-group-text p-group-text"
                                id="basic-addon1"
                              >
                                <i className="bi bi-person"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control p-control"
                              placeholder="First Name"
                              aria-label="fname"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-field">
                          <label htmlFor="lname">Last Name</label>
                          <div class="input-group p-group">
                            <div class="input-group-prepend p-prepend">
                              <span
                                class="input-group-text p-group-text"
                                id="basic-addon1"
                              >
                                <i className="bi bi-person"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control p-control"
                              placeholder="Last Name"
                              aria-label="lname"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div className="p-block row">
                      <div className="col-lg-6">
                        <div className="p-field">
                          <label htmlFor="phone">Phone Number</label>
                          <div class="input-group p-group">
                            <div class="input-group-prepend p-prepend">
                              <span
                                class="input-group-text p-group-text"
                                id="basic-addon1"
                              >
                                <i className="bi bi-telephone"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control p-control"
                              placeholder="00000 00000"
                              aria-label="phone"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-field">
                          <label htmlFor="email">Email ID</label>
                          <div class="input-group p-group">
                            <div class="input-group-prepend p-prepend">
                              <span
                                class="input-group-text p-group-text"
                                id="basic-addon1"
                              >
                                <i className="bi bi-envelope"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control p-control"
                              placeholder="abc@xyz.com"
                              aria-label="email"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div className="p-block row">
                      <div className="col-lg-6">
                        <div className="p-field">
                          <label htmlFor="address">Address</label>
                          <div class="input-group p-group">
                            <div class="input-group-prepend p-prepend">
                              <span
                                class="input-group-text p-group-text"
                                id="basic-addon1"
                              >
                                <i className="bi bi-house-door"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control p-control"
                              placeholder="Your Address"
                              aria-label="address"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-field">
                          <label htmlFor="dob">Date of Birth</label>
                          <div class="input-group p-group">
                            <input
                              type="text"
                              class="form-control p-control"
                              placeholder="dd/mm/yyyy"
                              aria-label="dob"
                              aria-describedby="basic-addon1"
                            />
                            <div class="input-group-prepend p-prepend">
                              <span
                                class="input-group-text p-group-text"
                                id="basic-addon1"
                              >
                                <i className="bi bi-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div className="p-block row">
                      <div className="col-lg-6">
                        <div className="p-field">
                          <label htmlFor="gender">Gender</label>
                          <div class="input-group p-group">
                            <select name="gender" id="gender">
                              <option value="female">Female</option>
                              <option value="male">Male</option>
                              <option value="none">Prefer not to say</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-success">
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileEdit;
