import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Blogs = () => {
  return (
    <>
      <Navbar />

      <div className="container-fluid bd-banner">
        <div className="bd-box">
          <h1>Blog</h1>
          <p>The proper uѕе оf ѕаfеtу ѕіgnѕ іѕ a соmрulѕоrу requirement.</p>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Blog Search"
              aria-label="blog search"
              aria-describedby="basic-addon2"
            />
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">
                Search Now
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="container b-container">
        <div className="row">
          <div className="col-lg-8">
            <div className="b-left">
              <div className="b-img">
                <img src="imgs/Agp-Blog-Jan-3 1.png" alt="" />
              </div>
              <div className="p-3">
                <p>
                  {" "}
                  <i className="bi bi-calendar4-week"></i> 02 June 2024{" "}
                  <span>
                    <i className="bi bi-clock"></i> 12:30pm{" "}
                  </span>{" "}
                  <span>
                    <i className="bi bi-eye"></i> 20k
                  </span>
                </p>

                <h1>Mauris et neque hendrerit bortis turpis giat nisl</h1>

                <h6>
                  Quality non lorem ac erat suscipit bibendum. Nulla facilisi.
                  Sedeuter nunc volutpat, mollis sapien vel, conseyer
                  turpeutionyer masin libero semper. Fusceler mollis augue sit
                  amet hendrerit vestibulum...
                </h6>

                <div className="b-button">
                <Link to="/blogDetails"><button>See More</button></Link>
                </div>
              </div>
            </div>
            <div className="b-left">
              <div className="b-img">
                <img src="imgs/Agp-Blog-Jan-3 1.png" alt="" />
              </div>
              <div className="p-3">
                <p>
                  {" "}
                  <i className="bi bi-calendar4-week"></i> 02 June 2024{" "}
                  <span>
                    <i className="bi bi-clock"></i> 12:30pm{" "}
                  </span>{" "}
                  <span>
                    <i className="bi bi-eye"></i> 20k
                  </span>
                </p>

                <h1>Mauris et neque hendrerit bortis turpis giat nisl</h1>

                <h6>
                  Quality non lorem ac erat suscipit bibendum. Nulla facilisi.
                  Sedeuter nunc volutpat, mollis sapien vel, conseyer
                  turpeutionyer masin libero semper. Fusceler mollis augue sit
                  amet hendrerit vestibulum...
                </h6>

                <div className="b-button">
                <Link to="/blogDetails"><button>See More</button></Link>
                </div>
              </div>
            </div>
            <div className="b-left">
              <div className="b-img">
                <img src="imgs/Agp-Blog-Jan-3 1.png" alt="" />
              </div>
              <div className="p-3">
                <p>
                  {" "}
                  <i className="bi bi-calendar4-week"></i> 02 June 2024{" "}
                  <span>
                    <i className="bi bi-clock"></i> 12:30pm{" "}
                  </span>{" "}
                  <span>
                    <i className="bi bi-eye"></i> 20k
                  </span>
                </p>

                <h1>Mauris et neque hendrerit bortis turpis giat nisl</h1>

                <h6>
                  Quality non lorem ac erat suscipit bibendum. Nulla facilisi.
                  Sedeuter nunc volutpat, mollis sapien vel, conseyer
                  turpeutionyer masin libero semper. Fusceler mollis augue sit
                  amet hendrerit vestibulum...
                </h6>

                <div className="b-button">
                <Link to="/blogDetails"><button>See More</button></Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="p-3 b-right">
              <h1>Top Best Posts</h1>
              <div className="b-box2">
                <p>Interdum et malesuada fames ac ante ipsum</p>
              </div>
              <div className="b-box2">
                <p>Duis aliquet lectus nec faucibus laoreet feugiat</p>
              </div>
              <div className="b-box2">
                <p>Sed condi mentum nim id luctus tempu que nibh</p>
              </div>

              <div className="py-4">
                <h1>Categories</h1>

                <div className="b-list">
                  <li>Trending News</li>
                  <li>Research & Analycis</li>
                  <li>Case Study</li>
                  <li>Lawyer Lifestyle</li>
                </div>
              </div>

              <div className="newsletter">
                <h1>Newsletter</h1>
                <input type="text" placeholder="Enter email" />
                <button>Subscribe Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Blogs;
