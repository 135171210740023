import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Courses = () => {
  return (
    <>
      <Navbar login={true} />

      <div className="container courses">
        <div className="c-heading">
          <h1>My Courses</h1>
        </div>
        <div className="row c-row">
          <div className="col-lg-6">
            <Link to='/details' className="text-decoration-none">
            <div className="c-card">
              <h2>SAFe® 3 Scrum Master</h2>
              <p>
                The SAFe Scrum Master Certification course acquaints you with
                the principles of a scaled agile framework enterprise and guides
                you through the process of planning and executing the Program
                Increment.
              </p>
              <h5>Instructor: <span> Meiyappan Muthu </span></h5>
            </div>
            </Link>
          </div>
          <div className="col-lg-6">
          <Link to='/details' className="text-decoration-none">
            <div className="c-card">
              <h2>SAFe® 3 Scrum Master</h2>
              <p>
                The SAFe Scrum Master Certification course acquaints you with
                the principles of a scaled agile framework enterprise and guides
                you through the process of planning and executing the Program
                Increment.
              </p>
              <h5>Instructor: <span> Meiyappan Muthu </span></h5>
            </div>
            </Link>
          </div>
          <div className="col-lg-6">
          <Link to='/details' className="text-decoration-none">
            <div className="c-card">
              <h2>SAFe® 3 Scrum Master</h2>
              <p>
                The SAFe Scrum Master Certification course acquaints you with
                the principles of a scaled agile framework enterprise and guides
                you through the process of planning and executing the Program
                Increment.
              </p>
              <h5>Instructor: <span> Meiyappan Muthu </span></h5>
            </div>
            </Link>
          </div>
          <div className="col-lg-6">
          <Link to='/details' className="text-decoration-none">
            <div className="c-card">
              <h2>SAFe® 3 Scrum Master</h2>
              <p>
                The SAFe Scrum Master Certification course acquaints you with
                the principles of a scaled agile framework enterprise and guides
                you through the process of planning and executing the Program
                Increment.
              </p>
              <h5>Instructor: <span> Meiyappan Muthu </span></h5>
            </div>
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Courses;
